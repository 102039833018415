.assets--filter {
  position: absolute;
  /* height: calc(100%); */
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 1;
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
  max-width: 300px;
}

.assets--filter .label__text {
  text-transform: capitalize;
}

.assets--filter.enabled {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.asset--filter--section {
  margin-bottom: var(--size-L);
  padding-bottom: var(--size-L);
  border-bottom: 1px solid var(--color-light-grey);
}

.assets--filter .section--header {
  text-transform: capitalize;
}

.assets--filter .section--header .label__text {
  font-weight: var(--font-weight-bold);
}

.assets--filter .section--header--check .label__text {
  font-size: var(--font-size-xxsmall);
}

.section--header--check:last-child .label {
  margin-bottom: 0;
}

.assets--filter button.button {
  min-width: 100%;
}

section {
  transition: all 0.15s ease;
  padding-left: 0;
}

section.__pushed {
  padding-left: 300px;
}
