.uploaderInner {
  border: 2px dashed var(--color-blue);
  cursor: pointer;
  transition: all 0.15s ease;
  -webkit-transition: all 0.15s ease;
}

.closeButton {
  right: calc(var(--size-S) + var(--size-M));
  top: 0;
  z-index: 999;
}

.uploaderInner:hover,
.isDragActive {
  background: var(--color-light-blue);
  border-color: var(--color-blue);
  color: var(--color-blue);
}

.isDragReject {
  background: var(--color-light-red);
  border-color: var(--color-red);
  color: var(--color-red);
}
