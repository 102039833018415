.tag__item {
  display: inline-block;
  border-radius: 5px;
  background: white;
  border: 0.125rem solid var(--color-light-grey);
  margin-right: var(--size-S);
  -webkit-transition: all var(--animation-duration) var(--animation-easing);
  transition: all var(--animation-duration) var(--animation-easing);
  cursor: pointer;
}

.tag__item.is-active,
.tag__item:hover {
  border-color: var(--color-navy);
}

.tag__item:last-child {
  margin-right: 0;
}
