button.tab--item {
  border: none;
  background: none;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0 1rem;
  outline: none;
  border-bottom: 4px solid transparent;
}

button.tab--item.is-active {
  font-weight: var(--font-weight-bold);
  color: var(--color-navy);
  border-color: var(--color-navy);
}

button.tab--item.is-active svg path {
  fill: var(--color-navy);
}

.tab--item svg {
  margin-right: 10px;
}
