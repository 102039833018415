.alert {
  cursor: pointer;
  margin: 10px;
  -webkit-animation: slideFromBottom 0.15s;
  animation: slideFromBottom 0.15s;
  transition: all 0.2s ease;
  border-radius: 5px;
  font-family: inherit;
  font-size: 0.9rem;
  min-height: initial;
  padding: 20px;
  box-shadow: none;
  border: 2px solid;
  font-weight: bolder;
  width: auto;
  text-align: center;
}

.alert.fixed--alert {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.alert-error {
  background-color: #ffdde0;
  border-color: #c62838;
  color: #c62838;
}

.alert-success {
  background-color: #e5f8e6;
  border-color: #32b73b;
  color: #32b73b;
}

.alert-info {
  background-color: #d4ebff;
  border-color: #0e64b1;
  color: #0e64b1;
}

.alert-warning {
  background-color: #0e64b1;
  border-color: #0e64b1;
  color: #d4ebff;
}

@-webkit-keyframes slideFromBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideFromBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
