table.table.assets--table--view thead > tr > th {
  text-transform: uppercase;
  color: var(--color-navy);
  letter-spacing: 0.125em;
  font-weight: bold;
  font-size: var(--font-size-xxsmall);
}

table.table.assets--table--view td:nth-child(4) {
  text-transform: capitalize;
}
