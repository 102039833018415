.pagination {
  list-style: none;
}

.pagination li {
  margin: 0 var(--size-S);
}

.pagination li button:hover,
.pagination li.active button {
  font-weight: bold;
  color: var(--color-green);
}
