.login-screen {
  background-color: var(--color-navy);
  background-image: url("/assets/login.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
}

@media (min-width: 900px) {
  .login-screen {
    background-position: right 30vw center;
  }
}
