.tab--bar {
  width: 100%;
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 0 0.5rem 0.5rem 0rem var(--color-light-grey);
  box-shadow: 0 0.5rem 0.5rem 0rem var(--color-light-grey);
}

.tab--bar .button {
  min-width: initial;
}
