.tag__input:hover,
.tag__input.focused {
  border-color: var(--color-navy);
}

.tag__input ul {
  padding: 0;
  margin: 0;
}

.tag__input ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: var(--size-XS);
}

.tag__input ul li:before {
  content: none;
}

.tag__input ul input {
  display: inline-block;
  width: 100%;
  background: transparent;
  border: 0.125rem solid transparent;
  padding: var(--size-S);
  outline: none;
  flex: 1;
}

.tag__input ul input:hover {
  border-color: transparent;
}
